.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #1d1d1d;
  padding: 20px;
  text-align: center;
}

.icon {
  color: #b7c8b5;
  transition: color 0.3s;
}
.icon:hover {
  color: #fe9000;
}
