.hero-img {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  height: 40vh;
  position: relative;
}

.hero-img::before {
  content: "";
  background: url(https://images.unsplash.com/photo-1542753363-3e1f8594e851?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-img h1 {
  font-size: 2.4rem;
}
.hero-img p {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 2rem;
}

@media screen and (max-width: 640px) {
  .hero-img h1 {
    font-size: 2rem;
  }
  .hero-img p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1900px) {
  .hero-img {
    background: rgba(0, 0, 0, 0.1);
    height: 50vh;
  }
  .hero-img h1 {
    font-size: 3.4rem;
  }
}
