@import url("https://fonts.googleapis.com/css2?family=Jost:wght@600&family=PT+Sans:wght@400;700&family=Poppins:wght@300;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Mono&family=Tinos:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  background: #262626;
}
h1 {
  color: #b7c8b5;
}

h4,
p,
a {
  color: #b7c8b5;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #fe9000;
  color: black;
  border: 1px solid black;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  background: transparent;
  color: #b7c8b5;
  border: 1px solid #b7c8b5;
}

.btn:hover {
  background-color: black;
  color: #fe9000;
  transition: 0.3s;
}
