/* Label styles */
label {
  color: #b7c8b5;
  margin-bottom: 0.5rem;
}

/* Form container styles */
form {
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;
  margin: auto;
  max-width: 600px;
  text-align: center; /* Center the form content horizontally */
}

/* Input and textarea styles */
form input,
form textarea {
  margin-bottom: 2rem;
  padding: 10px 18px;
  font-size: 1.2rem;
  background-color: #b7c8b5; /* Use rgba with reduced opacity */
  border-color: black;
  color: black; /* Text color */
  transition: background-color 0.3s, color 0.3s; /* Add transition effect */
}

/* Apply styles when input is focused or clicked */
form input:focus,
form textarea:focus {
  background-color: rgba(254, 144, 0, 0.8); /* Restore full opacity on focus */
  color: black;
  border-color: #b7c8b5;
}

/* Contact button styles */
.contact-btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #fe9000;
  color: black;
  border: 1px solid black;
  font-weight: 600;
  width: 50%; /* Adjust button width for mobile */
  margin: 0 auto; /* Center the button horizontally */
  cursor: pointer;
  margin-top: 1rem; /* Add some space between the button and the form */
  margin-bottom: 5rem;
}

/* Button hover effect */
.contact-btn:hover {
  background-color: black;
  color: #fe9000;
  transition: 0.3s;
}

/* Media query for smaller screens (e.g., phones) */
@media screen and (max-width: 480px) {
  /* Reduce padding for smaller screens */
  form {
    padding: 2rem 1rem;
  }

  /* Adjust input and textarea padding for smaller screens */
  form input,
  form textarea {
    padding: 8px 16px;
  }
}
