/* AboutContentStyles.css */

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  text-align: center;
  width: 100%;
}

.about-grid {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;
}

.about-text {
  flex: 1;
  text-align: center;
}

.about-header {
  font-size: 4rem;
  margin-bottom: 3rem;
  font-weight: bold;
  text-decoration: underline;
  color: #fe9000;
}

p {
  color: #b7c8b5;
  padding: 1rem;
}

.about-image {
  border: 2px solid #b7c8b5;
  padding: 10px;
  border-radius: 5px;
}

.profile-image {
  width: 400px;
  height: auto;
}

.about-contact {
  margin-top: 3rem;
  margin-bottom: 5rem;
  text-align: center;
}

.about-contact a {
  padding: 1rem 5rem;
  background-color: #fe9000;
  color: black;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.about-contact a:hover {
  background-color: black;
  color: #b7c8b5;
  transition: background-color 0.3s ease, color 0.3s ease;
}

@media screen and (max-width: 768px) {
  .about-header {
    font-size: 3rem;
  }

  .about-grid {
    flex-direction: column; /* Change to column on mobile screens */
    align-items: center; /* Center-align items horizontally on mobile screens */
  }

  .about-contact a {
    padding: 1rem 3rem; /* Adjust button padding for mobile screens */
  }
}
@media screen and (min-width: 1600px) {
  .about-header {
    font-size: 6rem;
  }
  p {
    font-size: 1.5rem;
  }
  .about-text {
    max-width: 800px;
  }
}
