/* MusicCardsStyles.css */

.music {
  width: 80%;
  margin: 3rem auto;
  padding-bottom: 3rem;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center; /* Center-align items horizontally within each cell */
}

.card {
  background-color: transparent; /* Set a transparent background */
  border: 5px solid rgba(183, 200, 181, 0.5); /* Default border color when not hovered (transparent with 0.5 opacity) */
  transition: border-color 0.3s ease; /* Add transition for border color change */
  position: relative; /* Needed for absolute positioning of hover text */
  padding: 10px; /* Add 2px of padding between the image and the border */
  margin-bottom: -5px; /* Adjust margin to prevent border from extending beyond the image */
}

.card img {
  width: 100%;
  height: 100%; /* Make the height match the width to create a perfect square */
  object-fit: cover; /* Ensure the image covers the entire square */
}

.card:hover {
  border-color: #fe9000; /* Change border color on hover */
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: rgba(254, 144, 0, 0.8); /* Adjust text color transparency */
  padding: 10px;
  border-radius: 5px;
  display: none; /* Hide the text by default */
}

.blur-on-hover:hover .hover-text {
  display: block; /* Show the text on hover */
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
